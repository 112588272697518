import { css, cx } from '@linaria/core';
import { mdiChevronLeft, mdiChevronRight } from '@mdi/js';
import Icon from '@mdi/react';
import { ButtonHTMLAttributes, ComponentProps, forwardRef, VFC } from 'react';
import { theme } from '~/style';
import { BaseButtonAnchor } from '../BaseButton';
type NavigationButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  direction?: 'left' | 'right';
};
type Props = ComponentProps<typeof BaseButtonAnchor> & {
  direction?: 'left' | 'right';
};
export const NavigationButton: VFC<NavigationButtonProps> = ({
  children,
  className,
  direction = 'left',
  ...props
}) => {
  return <button className={cx(className, navButton)} data-position={direction} disabled={props.disabled} {...props}>
      <Icon path={direction === 'left' ? mdiChevronLeft : mdiChevronRight} size="32px" />
      {children}
    </button>;
};
export const NavigationAnchorButton = forwardRef<HTMLAnchorElement, Props>(function NavigationAnchorButton({
  children,
  className,
  direction = 'left',
  ...props
}, ref) {
  return <a className={cx(className, navButton)} data-position={direction} ref={ref} {...props}>
        <Icon path={direction === 'left' ? mdiChevronLeft : mdiChevronRight} size="32px" />
        {children}
      </a>;
});
const navButton = "ntn65rt";

require("./NavigationButton.linaria.module.css!=!../../../../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./NavigationButton.tsx");