import { css, cx } from '@linaria/core';
import { AnchorHTMLAttributes, ButtonHTMLAttributes, ComponentProps, forwardRef, VFC } from 'react';
import { theme } from '~/style';
import { Icon } from '../../Icon';
type ButtonProps = ComponentProps<typeof Icon> & ButtonHTMLAttributes<HTMLButtonElement>;
type ButtonAnchorProps = Omit<ComponentProps<typeof Icon>, 'ref'> & AnchorHTMLAttributes<HTMLAnchorElement>;
export const IconButton: VFC<ButtonProps> = ({
  className,
  onClick,
  ...props
}) => <button className={cx(className, iconButton)} onClick={onClick} type={props.type} title={props.title} disabled={props.disabled}>
    <Icon {...props} />
  </button>;
export const IconButtonAnchor = forwardRef<HTMLAnchorElement, ButtonAnchorProps>(function IconButtonAnchor({
  className,
  ...props
}, ref) {
  return <a className={cx(className, iconButton)} ref={ref} {...props}>
        <Icon {...props} />
      </a>;
});
const iconButton = "iwp10fx";

require("./IconButton.linaria.module.css!=!../../../../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./IconButton.tsx");