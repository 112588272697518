import { css, cx } from '@linaria/core';
import { HTMLAttributes, memo, ReactNode, useRef, VFC } from 'react';
import ReactDOM from 'react-dom';
import { theme } from '~/style';
import { useAriaHidden } from './useAriaHidden';
import { useInert } from './useInert';
export type Props = HTMLAttributes<HTMLDivElement> & {
  children: ReactNode;
  isOpen: boolean;
  onClose: () => void;
};

const Modal: VFC<Props> = ({
  children,
  isOpen,
  className,
  onClose,
  'aria-labelledby': ariaLabelledby,
  ...props
}) => {
  const dialogRef = useRef<HTMLDivElement>(null);
  useAriaHidden(dialogRef, isOpen);
  useInert(isOpen);
  return isOpen ? ReactDOM.createPortal(<>
          <div className={overlay} onClick={onClose} />
          <div style={{
      '--scrollY': `${window.scrollY}px`
    }} className={cx(content, className)} role="dialog" aria-labelledby={ariaLabelledby} ref={dialogRef} {...props}>
            {children}
          </div>
        </>, document.body) : null;
};

export default memo(Modal);
const overlay = "oomxq72";
const content = "c1uyykwa";

require("./Modal.linaria.module.css!=!../../../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./Modal.tsx");