import { css } from '@linaria/core';
import React, { ComponentProps, memo, VFC } from 'react';
import { Button } from '~/components/elements/Button';
import { BaseButtonAnchor } from '~/components/elements/Button/BaseButton';
import { Modal } from '~/components/elements/Modal';
import { theme } from '~/style';
import { manaberuLink } from '~/utils/links';
export type RecommendModalModalProps = Omit<ComponentProps<typeof Modal>, 'children' | 'onClickButton' | 'title'>;

const RecommendModal: VFC<RecommendModalModalProps> = props => {
  return <Modal {...props} onClose={props.onClose}>
      <h1 className={titleText}>講座数が上限に達しました</h1>

      <div className={buttonWrap}>
        <Button>
          <BaseButtonAnchor href={manaberuLink.plan} className={link} target="_blank" rel="noopener noreferrer">
            プラン詳細はこちら
          </BaseButtonAnchor>
        </Button>
      </div>
    </Modal>;
};

export default memo(RecommendModal);
const titleText = "t1o7ules";
const buttonWrap = "b2xagtw";
const link = "l10wucfy";

require("./RecommendModal.linaria.module.css!=!../../../../../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./RecommendModal.tsx");