import { css, cx } from '@linaria/core';
import { mdiPlus } from '@mdi/js';
import Link from 'next/link';
import { memo, VFC } from 'react';
import { CreatedCourseCard, PurchasedCourseCard } from '~/components/containers/CourseCard';
import { ButtonAnchor, NavigationAnchorButton } from '~/components/elements/Button';
import { useDisclosure } from '~/components/elements/Modal/useModal';
import { theme } from '~/style';
import { color } from '~/style/color';
import { manaberuLink } from '~/utils/links';
import { CreatedCourses, PurchasedCourses } from '../../../../hooks/useGetCourses';
import { RecommendModal } from './RecommendModal';
type Props = {
  onClickCreate?: () => void;
  isCreating?: boolean;
  purchasedCourses: PurchasedCourses;
  createdCourses: CreatedCourses;
  isLimited: boolean;
};

const CoursesTop: VFC<Props> = ({
  onClickCreate,
  isCreating = false,
  purchasedCourses,
  createdCourses,
  isLimited
}) => {
  const [isOpen, onClose, onOpen] = useDisclosure();
  return <div className={wrapper}>
      <div className={header}>
        <h1 className={head1}>講座</h1>
        <Link href={isLimited ? '' : manaberuLink.newCourse} passHref={!isLimited}>
          <ButtonAnchor size="lg" iconPath={mdiPlus} onClick={isLimited ? onOpen : onClickCreate} isLoading={isCreating}>
            講座を作成
          </ButtonAnchor>
        </Link>
      </div>

      {createdCourses.length >= 1 && <div className={cx(borderBox, mb)}>
          <div className={titleWrapper}>
            <h2 className={head2}>作成した講座</h2>
            <Link href={manaberuLink.createdCourses} passHref>
              <NavigationAnchorButton direction="right">全て表示</NavigationAnchorButton>
            </Link>
          </div>
          <ul className={courses}>
            {createdCourses.map(course => <li key={course.id}>
                <CreatedCourseCard {...course} />
              </li>)}
          </ul>
        </div>}

      {purchasedCourses.length >= 1 && <div className={borderBox}>
          <div className={titleWrapper}>
            <h2 className={head2}>購入した講座</h2>
            <Link href={manaberuLink.subscribedCourses} passHref>
              <NavigationAnchorButton direction="right">全て表示</NavigationAnchorButton>
            </Link>
          </div>
          <ul className={courses}>
            {purchasedCourses.map(course => <li key={course.id}>
                <PurchasedCourseCard {...course} />
              </li>)}
          </ul>
        </div>}

      <RecommendModal isOpen={isOpen} onClose={onClose} />
    </div>;
};

export default memo(CoursesTop);
const wrapper = "wk2mn3u";
const head1 = "h1w8tyzv";
const header = "h1bfccwu";
const borderBox = "bfs6wo4";
const head2 = "hbyra38";
const courses = "c1vsdb29";
const mb = "m1b66l8c";
const titleWrapper = "t1uwmnzn";

require("./index.linaria.module.css!=!../../../../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./index.tsx");