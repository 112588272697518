import { css } from '@linaria/core';
import { mdiPlus } from '@mdi/js';
import { memo, VFC } from 'react';
import { ButtonAnchor } from '~/components/elements/Button';
import { theme } from '~/style';
type Props = {
  onClickCreate?: () => void;
  isCreating?: boolean;
};

const CoursesInitial: VFC<Props> = ({
  onClickCreate,
  isCreating = false
}) => {
  return <div className={wrapper}>
      <h1 className={head1}>講座</h1>

      <div className={container}>
        <h2 className={head2}>マナベルへようこそ👋</h2>
        <p className={desc}>
          まずは講座を作ってみましょう。テンプレートからかんたんに作成できます。
        </p>
        <ButtonAnchor size="lg" iconPath={mdiPlus} onClick={onClickCreate} isLoading={isCreating}>
          講座を作成
        </ButtonAnchor>
      </div>
    </div>;
};

export default memo(CoursesInitial);
const wrapper = "w1d9ui0w";
const head1 = "h1me20ls";
const head2 = "h151bhje";
const desc = "d17xhah";
const container = "c1jdmjae";

require("./index.linaria.module.css!=!../../../../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./index.tsx");