import { css } from '@linaria/core';
import { memo, VFC } from 'react';
import { Spinner } from '~/components/elements/Spinner';
import { useCreateStore } from '../../../hooks/useCreateStore';
import { useGetCourses } from '../../../hooks/useGetCourses';
import CoursesInitial from './Initial';
import CoursesTop from './Top';

const CoursesRoot: VFC = () => {
  const {
    createdCourses,
    purchasedCourses,
    isLimited,
    isLoading
  } = useGetCourses();
  const {
    onClickCreate,
    isLoading: isCreating
  } = useCreateStore();
  if (isLoading) return <div className={center}>
        <Spinner />
      </div>;
  return createdCourses.length || purchasedCourses.length ? <CoursesTop createdCourses={createdCourses.slice(0, 3)} purchasedCourses={purchasedCourses.slice(0, 3)} isLimited={isLimited} onClickCreate={onClickCreate} isCreating={isCreating} /> : <CoursesInitial onClickCreate={onClickCreate} isCreating={isCreating} />;
};

export default memo(CoursesRoot);
const center = "cefv44g";

require("./index.linaria.module.css!=!../../../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./index.tsx");