import { hideOthers, Undo } from 'aria-hidden'
import { RefObject, useEffect } from 'react'

export const useAriaHidden = (ref: RefObject<HTMLElement>, shouldHide: boolean) => {
  useEffect(() => {
    if (!ref.current) return undefined

    let undo: Undo | null = null

    if (shouldHide && ref.current) {
      undo = hideOthers(ref.current)
    }

    return () => {
      if (shouldHide) {
        undo?.()
      }
    }
  }, [shouldHide, ref])
}
