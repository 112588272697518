import { css, cx } from '@linaria/core';
import Icon from '@mdi/react';
import { ComponentProps, forwardRef, VFC } from 'react';
import { theme } from '~/style';
import { BaseButton, BaseButtonAnchor } from '../BaseButton';
type Props = {
  iconPath: string;
};
type ButtonProps = ComponentProps<typeof BaseButton> & Props;
type ButtonAnchorProps = ComponentProps<typeof BaseButtonAnchor> & Props;
export const ActionButton: VFC<ButtonProps> = ({
  children,
  iconPath,
  className,
  ...props
}) => <BaseButton className={cx(className, actionButton)} {...props}>
    <Icon path={iconPath} size="32px" />
    {children}
  </BaseButton>;
export const ActionButtonAnchor = forwardRef<HTMLAnchorElement, ButtonAnchorProps>(function GhostButtonAnchor({
  children,
  className,
  iconPath,
  ...props
}, ref) {
  return <a className={cx(className, actionButton)} ref={ref} {...props}>
        <Icon path={iconPath} size="32px" />
        {children}
      </a>;
});
const actionButton = "aqfuo9i";

require("./ActionButton.linaria.module.css!=!../../../../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./ActionButton.tsx");