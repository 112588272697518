import { useEffect } from 'react'
import 'wicg-inert'

export const useInert = (open: boolean) => {
  useEffect(() => {
    document.querySelector('#__next')?.toggleAttribute('inert', open)
    return () => {
      document.querySelector('#__next')?.toggleAttribute('inert', false)
    }
  }, [open])
}
