import axios from 'axios'
import { useRouter } from 'next/dist/client/router'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { api } from '~/api'
import { useGetStore } from '~/hooks/useGetStore'
import { FormError } from '~/style/types'
import { toastServerError } from '~/utils/error'
import { manaberuLink } from '~/utils/links'

export const useCreateStore = () => {
  const { push } = useRouter()
  const { mutate: createStore, isLoading: isCreateLoading } = useMutation(
    api.dashboard.dCreateStore,
    {
      onSuccess: () => {
        push(manaberuLink.newCourse)
      },
      onError: (err) => {
        if (!axios.isAxiosError(err)) return

        toastServerError(err)

        const errors: FormError[] = err.response?.data.errors ?? []
        errors.forEach((error) => {
          toast.error(error.message)
        })
      },
    }
  )

  // /courcesでは, useGetCoursesの方で呼ばれているので、実際はキャッシュを参照している
  const { data: storeData } = useGetStore({ retry: 1 })

  const onClickCreate = () => {
    if (storeData?.data) return push(manaberuLink.newCourse)
    createStore({})
  }

  return {
    onClickCreate,
    isLoading: isCreateLoading,
  }
}
