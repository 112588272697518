export const color = {
  brand: '#5db7c9',
  brandLight: '#79c4d2',
  brand10: 'rgba(93, 183, 201, 0.1)',
  brand20: 'rgba(93, 183, 201, 0.2)',
  brand50: 'rgba(93, 183, 201, 0.5)',
  textBlack: '#333333',
  textBlack80: 'rgba(51, 51, 51, 0.8)',
  textBlack60: 'rgba(51, 51, 51, 0.6)',
  textBlack50: 'rgba(51, 51, 51, 0.5)',
  textBlack40: 'rgba(51, 51, 51, 0.4)',
  textBlack20: 'rgba(51, 51, 51, 0.2)',
  textBlack10: 'rgba(51, 51, 51, 0.1)',
  textBlack5: 'rgba(51, 51, 51, 0.05)',
  white: '#ffffff',
  white05: 'rgba(255, 255, 255, 0.05)',
  white60: 'rgba(255, 255, 255, 0.6)',
  navy: '#0F3960',
  error: '#C9635D',
} as const

export type Color = typeof color
