import { css, cx } from '@linaria/core';
import { AnchorHTMLAttributes, ButtonHTMLAttributes, forwardRef, InputHTMLAttributes, LabelHTMLAttributes, ReactNode, VFC } from 'react';
type Props = {
  children: ReactNode;
};
type BaseButtonProps = Props & ButtonHTMLAttributes<HTMLButtonElement>;
type BaseButtonAnchorProps = Props & AnchorHTMLAttributes<HTMLAnchorElement>;
type FileButtonProps = Props & Omit<LabelHTMLAttributes<HTMLLabelElement>, 'onChange'> & Pick<InputHTMLAttributes<HTMLInputElement>, 'accept' | 'onChange'>;
export const BaseButton: VFC<BaseButtonProps> = ({
  children,
  className,
  type = 'button',
  ...props
}) => <button className={cx(base, className)} type={type} {...props}>
    {children}
  </button>;
export const BaseButtonAnchor = forwardRef<HTMLAnchorElement, BaseButtonAnchorProps>(function BaseButtonAnchor({
  children,
  className,
  ...props
}, ref) {
  return <a ref={ref} className={cx(base, className)} {...props}>
        {children}
      </a>;
});
export const BaseFileButton = forwardRef<HTMLInputElement, FileButtonProps>(function BaseFileButton({
  children,
  className,
  accept,
  onChange,
  ...props
}, ref) {
  return <label className={cx(base, className)} {...props}>
      <input ref={ref} type="file" hidden accept={accept} onChange={onChange} />
      {children}
    </label>;
});
const base = "bmwnup6";

require("./BaseButton.linaria.module.css!=!../../../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./BaseButton.tsx");