import { css, cx } from '@linaria/core';
import Icon from '@mdi/react';
import React, { ComponentProps, forwardRef, VFC } from 'react';
import { theme } from '~/style';
import { color } from '~/style/color';
import { Spinner } from '../../Spinner';
import { BaseButton, BaseButtonAnchor, BaseFileButton } from '../BaseButton';
type Props = {
  size?: 'lg' | 'md';
  variant?: 'primary' | 'secondary';
  iconPath?: string;
  isLoading?: boolean;
};
type ButtonProps = ComponentProps<typeof BaseButton> & Props;
type ButtonAnchorProps = ComponentProps<typeof BaseButtonAnchor> & Props;
type FileButtonProps = ComponentProps<typeof BaseFileButton> & Props;
const iconSize = {
  lg: '40px',
  md: '24px'
};
const iconColor = {
  secondary: color.brand,
  primary: color.white60
};
export const Button: VFC<ButtonProps> = ({
  children,
  className,
  iconPath,
  variant = 'primary',
  size = 'md',
  isLoading,
  ...props
}) => iconPath ? <BaseButton className={cx(className, primary, iconButton)} data-variant={variant} data-size={size} {...props}>
      <Icon path={iconPath} size={iconSize[size]} color={iconColor[variant]} />
      <span className={iconText}>{children}</span>
      {isLoading && <span className={spinner}>
          <Spinner size="sm" colorSchema={variant === 'primary' ? 'white' : 'green'} />
        </span>}
    </BaseButton> : <BaseButton className={cx(className, primary)} data-variant={variant} data-size={size} {...props}>
      {children}
      {isLoading && <span className={spinner}>
          <Spinner size="sm" colorSchema={variant === 'primary' ? 'white' : 'green'} />
        </span>}
    </BaseButton>;
export const ButtonAnchor = forwardRef<HTMLAnchorElement, ButtonAnchorProps>(function ButtonAnchor({
  children,
  className,
  size = 'md',
  variant = 'primary',
  iconPath,
  isLoading,
  ...props
}, ref) {
  return iconPath ? <BaseButtonAnchor ref={ref} className={cx(className, primary, iconButton)} data-variant={variant} data-size={size} {...props}>
      <Icon path={iconPath} size={iconSize[size]} />
      <span className={iconText}>{children}</span>
      {isLoading && <span className={spinner}>
          <Spinner size="sm" colorSchema={variant === 'primary' ? 'white' : 'green'} />
        </span>}
    </BaseButtonAnchor> : <BaseButtonAnchor ref={ref} className={cx(className, primary)} data-variant={variant} data-size={size} {...props}>
      {children}
      {isLoading && <span className={spinner}>
          <Spinner size="sm" colorSchema={variant === 'primary' ? 'white' : 'green'} />
        </span>}
    </BaseButtonAnchor>;
});
export const FileButton = forwardRef<HTMLInputElement, FileButtonProps>(function FileButton({
  children,
  variant = 'primary',
  className,
  size = 'md',
  iconPath,
  ...props
}, ref) {
  return iconPath ? <BaseFileButton className={cx(className, primary, iconButton)} data-variant={variant} data-size={size} ref={ref} {...props}>
      <Icon path={iconPath} size={iconSize[size]} color={iconColor[variant]} />
      <span className={iconText}>{children}</span>
    </BaseFileButton> : <BaseFileButton ref={ref} className={cx(className, primary)} data-variant={variant} data-size={size} {...props}>
      {children}
    </BaseFileButton>;
});
const primary = "py59tv5";
const iconButton = "i19st8hl";
const iconText = "i4s7noa";
const spinner = "s12zdh7a";

require("./Button.linaria.module.css!=!../../../../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./Button.tsx");